import { environment } from '@environments/environment';

export const baseUri = `${environment.apiUri}/moves`;
export const draftUri = `${baseUri}/draft`;
export const byAccessTokenAndIdUri = `${baseUri}/by-access-token-and-id`;
export const byEmailUri = `${baseUri}/by-email`;
export const registerUri: string = baseUri;
export const transfereeUri = `${baseUri}/transferee`;
export const registerForLeaverUri = `${baseUri}/leaver`;
export const emailConfirmUri = `${baseUri}/:id/confirm/send`;
export const confirmUri = `${baseUri}/:id/confirm`;
export const byIdUri = `${baseUri}/:id`;
export const getLocationSuggestionUri = `${baseUri}/:id/location-suggestion`;
export const submitUri = `${baseUri}/:id/submit`;
export const sendConfirmation = `${baseUri}/:id/send-confirmation`;
export const confirmOffersUri = `${baseUri}/:id/confirm-offers`;
export const confirmEnergyUri = `${baseUri}/:id/confirm-energy`;
export const expireMoveUri = `${baseUri}/:id/expire`;
export const activateMoveUri = `${baseUri}/:id/activate`;
export const deleteMoveUri = `${byIdUri}/delete`;
export const countUri = `${baseUri}/count`;
export const realEstateAgentUri = `${baseUri}/real-estate-agent`;
export const weeklyMoveCountsUri = `${baseUri}/move-count-weekly`;
export const createdByFlowQuarterlyUri = `${baseUri}/by-created-flow-quarterly`;
export const movingDateVsCreatedOnGapUri = `${baseUri}/moving-date-vs-created-on-gap`;
export const realEstateAgentUrgentTodoCountUri = `${baseUri}/real-estate-agent-todo-count`;
export const adminUri = `${baseUri}/admin`;
export const mailAdvanceAmountUri = `${byIdUri}/mail-advance-amount`;
export const smsConfirmEnergyUri = `${byIdUri}/sms-confirm-energy`;
export const energyMeterReadingAssetsUri = `${byIdUri}/energy/meter-reading-assets`;
export const energyDocumentAssetsUri = `${byIdUri}/energy/document-assets`;
export const energyAssetsUri = `${byIdUri}/energy/all-assets`;
export const contactLogUri = `${byIdUri}/contact-log`;
export const contactLogForAddresseesUri = `${byIdUri}/contact-log-addressees`;
export const conversionUri = `${baseUri}/convert`;
export const redoContactLogUri = `${baseUri}/:id/contact-logs/:contactLogId/redo`;
export const reviewHistoryUri = `${byIdUri}/reviews-history`;

export const quarterlyForRealEstateGroupUri = `${baseUri}/quarterly/:realEstateGroup/:year/:quarter`;

export const editReviewSuggestionUri = `${byIdUri}/suggestion`;
export const editWaterMeterReadingsUri = `${byIdUri}/water-meter-readings`;
export const getAllLogsUri = `${byIdUri}/all-logs`;
